export default class ImageHelper {
	public static getElementDisplayID(element: HTMLElement) {
		let id = element.tagName ?? "?";
		if (element.id) id += "-" + element.id;
		else if (element.className) id += "-" + element.className;
		return id;
	}

	public static bindImage(element: HTMLImageElement, data: ResponsiveImageOutput) {
		console.log("Binding image data to element:", ImageHelper.getElementDisplayID(element), data);
		element.src = data.src;
		element.srcset = data.srcSet;
		element.ariaPlaceholder = data.placeholder;
		/* element.width = data.width;
		element.height = data.height; */
	}

	public static getImageContainerById(id: string): HTMLImageElement {
		const element = document.getElementById(id);
		if (element instanceof HTMLImageElement) return element;
		return null;
	}

	public static getImageContainersByClass(name: string): HTMLImageElement[] {
		const elements = document.getElementsByClassName(name);
		const results: HTMLImageElement[] = [];
		for (const element of elements) {
			if (element instanceof HTMLImageElement) results.push(element);
		}
		return results;
	}

	public static bindImagesByClass(name: string, data: ResponsiveImageOutput): void {
		const containers = ImageHelper.getImageContainersByClass(name);
		for (const container of containers) {
			ImageHelper.bindImage(container, data);
		}
	}
}
