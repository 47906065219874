import Helper from "./helper";

const themes = [
	['Light', () => 'light'],
	['Dark', () => 'dark'],
	['Os', () => getPreferredTheme(true)]
];

export function setWindowTheme() {
	/* Check if theme is in localstorage. */
	const theme = localStorage.getItem('theme');
	const root = document.getRootNode();
	if (root instanceof Document) {
		const body = root.body;
		body.setAttribute('class', theme || getPreferredTheme());
	}
}

export function getPreferredTheme(noStorage: boolean = false) {
	const themeStore = localStorage.getItem('theme');
	if (themeStore && !noStorage) {
		return themeStore;
	} else if (matchMedia && matchMedia('(prefers-color-scheme: dark)').matches) {
		return 'dark';
	}
	return 'light';
}

export function bindThemeEvents() {
	const themeButton = $('.theme-switcher');
	if (themeButton !== null) {
		themeButton.on('click', function (ev) {
			/* Generate theme selection */
			const themeContainer = $('.theme-switcher-container');
			if (themeContainer !== null) {
				if (themeContainer.hasClass('active')) {
					$('.themes-menu').remove();
					themeContainer.removeClass('active');
					return;
				}
				const output = document.createDocumentFragment();

				const themeList = Helper.element("ul");
				themeList.classList.add("simple", "themes-menu");
				themes.forEach(e => {
					const [label, getFn] = e;
					const themeItem = Helper.element("li");
					const themeButton = Helper.element("button");
					themeButton.addEventListener("click", () => {
						const body = $('body');
						if (body !== null) {
							body.removeClass('light dark');
							const selectedTheme = typeof getFn === "function" ? getFn() : getFn;
							body.addClass(selectedTheme);
							localStorage.setItem('theme', selectedTheme);
						}
					});
					const themeWrapper = Helper.element("span", {
						textContent: typeof label === "function" ? label() : label,
					});
					themeWrapper.classList.add("button-wrapper");
					themeButton.append(themeWrapper);
					themeItem.append(themeButton);
					themeList.append(themeItem);
				});

				output.append(themeList);
				themeContainer.append(output);
				themeContainer.addClass('active');
			}
		});
	}
}
