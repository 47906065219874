export default class Helper {
	public static div(props?: Partial<HTMLDivElement>): HTMLDivElement {
		const element = Helper.element("div", props);
		return element;
	}

	public static svg(): SVGElement {
		const element = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		return element;
	}

	public static img(props?: Partial<HTMLImageElement>): HTMLImageElement {
		const element = Helper.element("img", props);
		return element;
	}

	public static test(props?: Partial<HTMLDivElement>): HTMLDivElement {
		const element = Helper.element("div", props);
		return element;
	}

	public static element<T extends HTMLElement>(tagName: string, props?: Partial<T>): T {
		const element = document.createElement(tagName) as T;
		Object.assign(element, props);
		return element;
	}
}
