// Asset imports
import "../css/main.css";
import "../css/themes.css";
// Asset icon imports
import fabricationImages from "../assets/fabrication.webp?sizes[]=64,sizes[]=96,sizes[]=512#rl";
import machiningImages from "../assets/machining.webp?sizes[]=64,sizes[]=96,sizes[]=512#rl";
import weldingImages from "../assets/welding.webp?sizes[]=64,sizes[]=96,sizes[]=512#rl";
import designingImages from "../assets/designing.webp?sizes[]=64,sizes[]=96,sizes[]=512#rl";
import supplyingImages from "../assets/supplying.webp?sizes[]=64,sizes[]=96,sizes[]=512#rl";
import repairingImages from "../assets/repairing.webp?sizes[]=64,sizes[]=96,sizes[]=512#rl";
import additionalImages from "../assets/additional.webp?sizes[]=64,sizes[]=96,sizes[]=512#rl";
import sunSvg from "../assets/sun.svg";
// Functional imports
import ImageHelper from "./image-helper";
import { bindThemeEvents, setWindowTheme } from "./theme";
import ImageRepository from "./image-repository";
import Slide from "./image-slide";

interface ImageCache {
	[x: string]: ResponsiveImageOutput;
}

interface SvgCache {
	[x: string]: any;
}

function generateNavList() {
	const output = document.createDocumentFragment();
	navDefault.forEach((item) => {
		const { link, label } = item;
		const navItem = document.createElement("li");
		output.appendChild(navItem);
		/* Change to button maybe */
		const navLink = document.createElement("a");
		navLink.setAttribute("class", "nav-item");
		navLink.setAttribute("href", link);
		navLink.textContent = label;
		navItem.appendChild(navLink);
	});
	return output;
}

function createDynamicContent() {
	const navLists = document.querySelectorAll("nav > .nav-list");
	const navList = generateNavList();
	for (const element of navLists) {
		element.appendChild(navList);
	}
}

function bindNavEvents() {
	const navButton = $('.nav-button');
	if (navButton !== null) {
		/* Element exists. */
		navButton.on('click', function (ev) {
			const navButton = $(this);
			const navContainer = $('header .navigation');
			if (navContainer !== null) {
				navContainer.toggleClass('active');
			}
			if (navButton !== null) {
				navButton.toggleClass('active');
			}
		});
	}
}

function bindServicesEvents() {
	/* Selects the (typically) button elements found in the services grid layout. */
	const services = document.querySelectorAll('.services-container > .services > button');
	console.log(services);
	services.forEach(service => {
		service.addEventListener('click', (ev) => {
			if (service.hasAttribute('ref')) {
				/* Process what's "active" our of all service buttons. */
				services.forEach(service => service.classList.remove('active'));
				service.classList.add('active');
				/* Setup to activate the target description element. */
				const ref = service.getAttribute('ref');
				const serviceDescs = document.querySelectorAll('.service-descriptions > *');
				const targets = document.querySelectorAll('.' + ref);
				serviceDescs.forEach(service => service.classList.remove('active'));
				targets.forEach(target => target.classList.add('active'));
				if (targets.length > 0 && window.innerWidth <= 769) {
					targets[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
			}
		});
	});
}

/* Response is:
{
	'imageDir': [
		'image1.webp',
		'image2.webp'
		...
	],
	'image2Dir': [
		'image1.webp',
		'image2.webp'
		...
	],
}
*/

console.log("Initialising webpage");
console.log(sunSvg);

ImageRepository.loadAll();

const caches: ImageCache = {
	"img-fabrication": fabricationImages,
	"img-machining": machiningImages,
	"img-welding": weldingImages,
	"img-designing": designingImages,
	"img-supplying": supplyingImages,
	"img-repairing": repairingImages,
	"img-additional": additionalImages,
	"img-awning": ImageRepository.get("awning"),
	"img-railing": ImageRepository.get("railing"),
};

console.log(caches);

const svgCache: SvgCache = {
	"svg-logo-container": sunSvg,
};

for (const key in caches) {
	if (Object.prototype.hasOwnProperty.call(caches, key)) {
		const element = caches[key];
		ImageHelper.bindImagesByClass(key, element);
	}
}

const navDefault = [
	{
		link: "./index.html",
		label: "Home",
	},
	{
		link: "./contact.html",
		label: "Contact us",
	},
];

createDynamicContent();
setWindowTheme();

const svgLogos = document.getElementsByClassName("svg-theme");
for (const svgLogo of svgLogos) {
	if (svgLogo instanceof HTMLImageElement) {
		svgLogo.src = sunSvg;
	}
}

/* getImageFiles().then(e => {
	bindImageViewerEvents(e);
}); */
bindNavEvents();
bindThemeEvents();
bindServicesEvents();

// Bind events to slide
const slide = new Slide();
if (!slide.load(ImageRepository.getAll())) {
	console.error("Slide failed to load images.");
}
const slideImgElement = document.querySelector("#image-gallery img.slide-img");
if (slideImgElement instanceof HTMLImageElement) {
	slide.bindDisplay(slideImgElement);
	slide.update();
}
const slideNextElement = document.querySelector("#image-gallery button.next");
if (slideNextElement instanceof HTMLButtonElement) {
	slide.bindNext(slideNextElement);
}
const slideBackElement = document.querySelector("#image-gallery button.back");
if (slideBackElement instanceof HTMLButtonElement) {
	slide.bindBack(slideBackElement);
}
