export default class Slide {
	protected images: ResponsiveImageOutput[] = [];
	protected index: number = 0;
	protected display: HTMLImageElement = null;

	/**
	 * default ctor
	 */
	constructor() {}

	/**
	 * load
	 * Function to get array of image data URLs/objs
	 */
	public load(data: ResponsiveImageOutput[]) {
		this.images = data;
		return true;
	}

	public get() {
		return this.images[this.index];
	}

	public reset() {
		this.index = 0;
		return this.index;
	}

	/**
	 * next image
	 */
	public next() {
		if (this.index >= this.images.length - 1) this.index = 0;
		else this.index++;
		return this.index;
	}

	/**
	 * back image
	 */
	public back() {
		if (this.index <= 0) this.index = this.images.length - 1;
		else this.index--;
		return this.index;
	}

	public bindDisplay(element: HTMLImageElement) {
		this.display = element;
		return true;
	}

	public bindNext(element: HTMLButtonElement) {
		element.addEventListener("click", (e) => {
			console.log("Slide:next triggered click event.");
			console.debug(e);
			this.next();
			this.update();
		});
		return true;
	}

	public bindBack(element: HTMLButtonElement) {
		element.addEventListener("click", (e) => {
			console.log("Slide:next triggered click event.");
			console.debug(e);
			this.back();
			this.update();
		});
		return true;
	}

	public update() {
		const display = this.display;
		if (display == null) {
			console.error("Slide:update display not bound.");
			return false;
		}
		const data = this.get();
		if (data == null) {
			console.error("Slide:update image fetch failed. Index:", this.index);
			return false;
		}
		display.src = data.src;
		display.srcset = data.srcSet;
		display.ariaPlaceholder = data.placeholder;
		return true;
	}
}
