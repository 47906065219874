// Asset image repository
import awningImages from "../assets/awning.webp?sizes[]=4000,sizes[]=2000,sizes[]=1000,sizes[]=500#rl";
import railingImages from "../assets/railing.webp?sizes[]=4000,sizes[]=2000,sizes[]=1000,sizes[]=500#rl";
import h1 from "../assets/h1.jpg?sizes[]=4608,sizes[]=2304,sizes[]=1152,sizes[]=576#rl";
import h2 from "../assets/h2.jpg?sizes[]=2272,sizes[]=1136,sizes[]=568,sizes[]=284#rl";
import h3 from "../assets/h3.jpg?sizes[]=4608,sizes[]=2304,sizes[]=1152,sizes[]=576#rl";
import h4 from "../assets/h4.jpg?sizes[]=2272,sizes[]=1136,sizes[]=568,sizes[]=284#rl";
import h5 from "../assets/h5.jpg?sizes[]=4608,sizes[]=2304,sizes[]=1152,sizes[]=576#rl";
import h6 from "../assets/h6.jpg?sizes[]=2272,sizes[]=1136,sizes[]=568,sizes[]=284#rl";
import a1 from "../assets/a1.jpg?sizes[]=640,sizes[]=320,sizes[]=160,sizes[]=80#rl";
import a2 from "../assets/a2.jpg?sizes[]=1280,sizes[]=640,sizes[]=320,sizes[]=160#rl";
import a3 from "../assets/a3.jpg?sizes[]=1752,sizes[]=876,sizes[]=438,sizes[]=219#rl";
import a4 from "../assets/a4.jpg?sizes[]=2832,sizes[]=1416,sizes[]=708,sizes[]=354#rl";
import a5 from "../assets/a5.jpg?sizes[]=3264,sizes[]=1632,sizes[]=816,sizes[]=408#rl";
import a6 from "../assets/a6.jpg?sizes[]=4000,sizes[]=2000,sizes[]=1000,sizes[]=500#rl";

export default class ImageRepository {
	protected static images = new Map<string, ResponsiveImageOutput>();

	public static loadAll() {
		ImageRepository.load("awning", awningImages);
		ImageRepository.load("railing", railingImages);
		ImageRepository.load("h1", h1);
		ImageRepository.load("h2", h2);
		ImageRepository.load("h3", h3);
		ImageRepository.load("h4", h4);
		ImageRepository.load("h5", h5);
		ImageRepository.load("h6", h6);
		ImageRepository.load("a1", a1);
		ImageRepository.load("a2", a2);
		ImageRepository.load("a3", a3);
		ImageRepository.load("a4", a4);
		ImageRepository.load("a5", a5);
		ImageRepository.load("a6", a6);
		return true;
	}

	public static load(key: string, value: ResponsiveImageOutput) {
		ImageRepository.images.set(key, value);
		return true;
	}

	public static get(key: string) {
		return ImageRepository.images.get(key);
	}

	public static getAll() {
		const result = [];
		for (const [key, data] of this.images) {
			result.push(data);
		}
		return result;
	}
}
